import { MarkRequired } from "ts-essentials"
import { GraphClient } from "../../client/httpClient/HttpClientTypes"
import { isValidGeoJsonPoint } from "../../common/geometry"
import { isDefined } from "../../common/validation"
import { DeviceFilterOptionsEnum } from "../../redux/reducers/deviceReducer"
import { ApiItemConfiguration } from "../apiConfig"
import { GeoJson, UserProfileResponse } from "./DeviceQueryResponse"
import {
  AlarmType,
  BinlevelType,
  BoltV2Type,
  DestroyAlarmInput,
  Device,
  DualBatteryVoltageType,
  GeofenceAlarmInput,
  IdentifierInput,
  MutationResponseGraphType,
  Oyster3BluetoothType,
  Oyster3Type,
  OysterType,
  PageInfo,
  PeopleCounterType,
  ResponseStatus,
  ScalarAlarmInput,
  TenantType,
  TenantTypeDeviceArgs,
  TenantTypeDeviceHistoryArgs,
  TenantTypeDevicesArgs,
  TriggerComparison,
  UnionOfGraphDeviceTypesConnection,
} from "./ManagementApi.autogenerated"

const deviceIdentitifierFields = `
  id
  tenantUrn
  deviceUrn
  schemaUrn
  hardwareId
`

const oysterFields = `
  ${deviceIdentitifierFields}
  name
  location
  address
  timestamp
  batteryVoltage


  inTrip
  speed
  heading
  fixFailed
  gps_fix_timestamp
`

const peopleCounterFields = `
  ${deviceIdentitifierFields}

  name
  location
  address
  timestamp
  batteryVoltage


  delta
  totalCount
`

const binlevelFields = `
  ${deviceIdentitifierFields}

  name
  location
  address
  timestamp

  batteryVoltage
  temperature
  laserDistance
  ultrasonicDistance
  laserReflectance
  tiltAngle
  tiltFlag
  fullDistance
  emptyDistance
  binLevel
  motionFlag
`

const dualBatteryVoltageFields = `
  ${deviceIdentitifierFields}
  name
  timestamp
  batteryVoltage
  externalVoltage
`
const boltV2Fields = `
  ${deviceIdentitifierFields}
  name
  location
  address
  timestamp

  speed
  heading

  vbat
  vext

  tripDistance
  tripRunTime
  odometer
`

const alarmSelector = `
alarms {
  id
  version
  name
  threshold
  isTriggered
  status
  description
  createdAt
  triggerType
}`

const mutationResponseFragment = `
status
data { id }`

const tenantQuery = `query getTenant {
  tenant {
    tenantUrn
    dashboardUrl
  }
}`
export type TenantResponse = Pick<TenantType, "tenantUrn" | "dashboardUrl">

const combinedDeviceUnionQuery = `query getTennantDevice($deviceUrn:String!) {
  tenant {
    tenantUrn

    device(deviceUrn: $deviceUrn) {
      ... on OysterType {
        ${oysterFields}
        ${alarmSelector}
      }
      ... on PeopleCounterType {
        ${peopleCounterFields}
        ${alarmSelector}
      }
      ... on BinlevelType {
        ${binlevelFields}
        ${alarmSelector}
      }
      ... on DualBatteryVoltageType {
        ${dualBatteryVoltageFields}
      }
      ... on BoltV2Type {
        ${boltV2Fields}
        ${alarmSelector}
      }
      ... on Oyster3Type {
        ${boltV2Fields}
        ${alarmSelector}
      }
      ... on Oyster3BluetoothType {
        ${boltV2Fields}
      }
    }
  }
}`

const combinedDeviceUnionSearch = `query searchTenantDevices($nameFilter:String, $take:Int) {
  tenant {
    tenantUrn

    devices(nameFilter:$nameFilter, take:$take) {
      ... on OysterType {
        ${oysterFields}
        ${alarmSelector}
      }
      ... on PeopleCounterType {
        ${peopleCounterFields}
        ${alarmSelector}
      }
      ... on BinlevelType {
        ${binlevelFields}
        ${alarmSelector}
      }
      ... on DualBatteryVoltageType {
        ${dualBatteryVoltageFields}
      }

      ... on BoltV2Type {
        ${boltV2Fields}
        ${alarmSelector}
      }
      ... on Oyster3Type {
        ${boltV2Fields}
        ${alarmSelector}
      }
      ... on Oyster3BluetoothType {
        ${boltV2Fields}
      }
    }
  }
}`

const deviceHistoryQuery = `
query history($deviceUrn:String!, $first:Int, $after:String, $start:DateTimeOffset, $end:DateTimeOffset) {
	tenant {
		tenantUrn
		deviceHistory(deviceUrn:$deviceUrn, first:$first, after:$after, start:$start, end:$end) {
			totalCount
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			edges {
				node {
          ... on OysterType {
            ${oysterFields}
          }
          ... on PeopleCounterType {
            ${peopleCounterFields}
          }
          ... on BinlevelType {
            ${binlevelFields}
          }
          ... on DualBatteryVoltageType {
            ${dualBatteryVoltageFields}
          }
          ... on BoltV2Type {
            ${boltV2Fields}
          }
          ... on Oyster3Type {
            ${boltV2Fields}
          }
          ... on Oyster3BluetoothType {
            ${boltV2Fields}
          }
			}
		}
		}
	}
}`

const userProfileQuery = `query getUserProfile {
  profile {
    id
    displayName
    userPrincipalName,
    country,
    givenName,
    mail,
    tenantUrn
  }
}`

const createGeoFenceAlarmMutation = `mutation createGeoFenceAlarm($alarm: geofenceAlarmInput!){
  createGeoFenceAlarm(input:  $alarm){
    ${mutationResponseFragment}
  }
}`

const createScalarAlarmMutation = `mutation createScalarAlarm($alarm: scalarAlarmInput!){
  createScalarAlarm(input:  $alarm){
  	${mutationResponseFragment}
  }
}`

const destroyAlarmMutation = `mutation destroyAlarm($alarmId: destroyAlarmInput!){
  destroyAlarm(input: $alarmId){
    ${mutationResponseFragment}
  }
}`

const replayEventsMutation = `mutation replayEvents($aggregateId: identifierInput!){
  replayEvents(input: $aggregateId){
    ${mutationResponseFragment}
  }
}`

export type SingleDeviceResponse = Device
export type MaybeSingleDeviceResponse = Device | undefined
export type SingleLocationDeviceResponse =
  | OysterType
  | BinlevelType
  | PeopleCounterType
  | BoltV2Type
  | Oyster3Type
  | Oyster3BluetoothType

export function isOysterResponse(response: SingleDeviceResponse): response is OysterType {
  return response && response.schemaUrn === "urn:p8:schema:oyster"
}

export function isPeopleCounterResponse(response: SingleDeviceResponse): response is PeopleCounterType {
  return response && response.schemaUrn === "urn:p8:schema:peoplesense"
}

export function isBinLevelResponse(response: SingleDeviceResponse): response is BinlevelType {
  return response && response.schemaUrn === "urn:p8:schema:binlevel"
}

export function isDualBatteryVoltageResponse(
  response: MaybeSingleDeviceResponse,
): response is DualBatteryVoltageType {
  return !!response && response.schemaUrn === "urn:p8:schema:dual-battery-voltage"
}

export function isBoltV2Response(response: MaybeSingleDeviceResponse): response is BoltV2Type {
  return !!response && response.schemaUrn === "urn:p8:schema:boltv2"
}

export function isOyster3Response(response: MaybeSingleDeviceResponse): response is BoltV2Type {
  return !!response && response.schemaUrn === "urn:p8:schema:oyster3"
}

export function isOyster3BlueToothResponse(response: MaybeSingleDeviceResponse): response is BoltV2Type {
  return !!response && response.schemaUrn === "urn:p8:schema:oyster3-bluetooth"
}

export function isDevice(response: unknown): response is SingleDeviceResponse {
  const r = response as SingleDeviceResponse
  return !!r && !!r.schemaUrn
}

export function isLocationDevice(response: unknown): response is SingleLocationDeviceResponse {
  const r = response as MaybeSingleDeviceResponse
  if (!r) return false
  if (isDualBatteryVoltageResponse(r)) return false
  return isDevice(r)
}

export function hasLocation(
  response: unknown,
): response is MarkRequired<SingleLocationDeviceResponse, "location"> {
  const r = response as SingleLocationDeviceResponse
  if (!r) return false
  return isValidGeoJsonPoint(r.location)
}

export function validateMutationResponse(
  response: MutationResponseGraphType,
  defaultMessage: string,
): MutationResponseGraphType {
  if (response.status !== ResponseStatus.Ok) {
    const errorMessage = response.errors && response.errors.length > 0 ? response.errors[0] : defaultMessage
    throw new Error(errorMessage)
  }

  return response
}

export type GeoFenceAlarmType = "GeoFence"
export type ScalarAlarmType = "ToiletCleaningAlarm" | "GenericCounter" | "BinLevel"

export function findAlarmOfType(
  device: Device,
  alarmType: GeoFenceAlarmType | ScalarAlarmType,
): AlarmType | undefined {
  return (device.alarms || []).find((x) => x.name === alarmType)
}

export class TenantQLQueries {
  constructor(
    protected client: GraphClient,
    protected apiConfig: ApiItemConfiguration,
  ) {}

  getUserProfile(): Promise<UserProfileResponse> {
    return this.client.query<UserProfileResponse>(this.apiConfig.url, userProfileQuery, {})
  }

  getDevice(deviceUrn: string): Promise<SingleDeviceResponse | undefined> {
    return (
      this.client
        .query<{ tenant: { device: Device | undefined } }, TenantTypeDeviceArgs>(
          this.apiConfig.url,
          combinedDeviceUnionQuery,
          {
            deviceUrn: deviceUrn,
          },
        )
        //.then(x => x.tenant.oyster || x.tenant.peoplecounter)
        .then((x) => x.tenant.device)
    )
  }

  /*
    NOTE: Untill the back end is updated items are loaded  from oldest to newest.
    so start must be older than end

    @startDate When Start date not specified starts from most recent
    @endDate When end date not specified continues until no more data
  */
  async loadDeviceHistory(arg: {
    deviceUrn: string
    startDate?: number
    endDate?: number
    continuationToken?: string
  }): Promise<{ data: Array<Device>; pageInfo: PageInfo }> {
    type DeviceHistoryResponse = {
      tenant: {
        tenantUrn: string
        deviceHistory: UnionOfGraphDeviceTypesConnection
      }
    }

    const response = await this.client.query<DeviceHistoryResponse, TenantTypeDeviceHistoryArgs>(
      this.apiConfig.url,
      deviceHistoryQuery,
      {
        first: 100,
        start: arg.startDate ? new Date(arg.startDate).toISOString() : undefined,
        end: arg.endDate ? new Date(arg.endDate).toISOString() : undefined,
        deviceUrn: arg.deviceUrn,
        after: arg.continuationToken,
      },
    )

    // console.log("Raw History:", JSON.stringify(response))
    const raw = response.tenant.deviceHistory
    if (!raw.edges) return { data: [], pageInfo: raw.pageInfo }

    const definedEdges = raw.edges.flatMap((x) => x.node).filter(isDefined)
    return {
      data: definedEdges,
      pageInfo: raw.pageInfo,
    }
  }

  searchDeviceByName(searchQuery = ""): Promise<Array<SingleDeviceResponse>> {
    return this.client
      .query<{ tenant: { devices: Array<Device> } }, TenantTypeDevicesArgs>(
        this.apiConfig.url,
        combinedDeviceUnionSearch,
        {
          nameFilter: searchQuery,
          take: 0,
        },
      )
      .then((x) => x.tenant.devices)
  }

  // searchDeviceByPosition(point: Point): Promise<Array<OysterResponse>> {
  //   return this.client.query<DeviceQueryResponse>(
  //     this.api.url,
  //     tenantSearchOysterDeviceByPositionQuery,
  //     { point: point }
  //   ).then(r => r.tenant.oysters);
  // }

  searchDevice(filter: DeviceFilterOptionsEnum): Promise<Array<SingleDeviceResponse>> {
    switch (filter) {
      case DeviceFilterOptionsEnum.FromMe30km:
        throw new Error("Invalid Filter Selection")

      case DeviceFilterOptionsEnum.LocationAlarm:
        return this.searchDeviceByName().then((devices) =>
          devices.filter((x) => !!x.alarms && x.alarms.length > 0),
        )

      case DeviceFilterOptionsEnum.All:
      default:
        return this.searchDeviceByName()
    }
  }

  getTenant(): Promise<TenantResponse> {
    return this.client
      .query<{ tenant: TenantResponse }>(this.apiConfig.url, tenantQuery, {})
      .then((x) => x.tenant)
  }
}

export class TenantMutations {
  constructor(
    protected client: GraphClient,
    protected api: ApiItemConfiguration,
  ) {}

  createScalarAlarm(
    name: ScalarAlarmType,
    deviceUrn: string,
    threshold: number,
    operator: TriggerComparison,
    offset: number,
    propertyPath: string,
    destroy?: boolean,
  ): Promise<MutationResponseGraphType> {
    return this.client
      .mutate<{ createScalarAlarm: MutationResponseGraphType }, { alarm: ScalarAlarmInput }>(
        this.api.url,
        createScalarAlarmMutation,
        {
          alarm: {
            alarmName: name,
            deviceUrn: deviceUrn,
            arm: true,
            threshold: threshold,
            offset: offset,
            operator: operator,
            propertyPath: propertyPath,
            destroyExisting: destroy,
          },
        },
      )
      .then((x) => x.createScalarAlarm)
  }

  createGeoFenceAlarm(
    name: GeoFenceAlarmType,
    deviceUrn: string,
    centroid: GeoJson,
    radius: number,
    operator: TriggerComparison,
    propertyPath: string,
  ): Promise<MutationResponseGraphType> {
    return this.client
      .mutate<{ createGeoFenceAlarm: MutationResponseGraphType }, { alarm: GeofenceAlarmInput }>(
        this.api.url,
        createGeoFenceAlarmMutation,
        {
          alarm: {
            alarmName: name,
            deviceUrn: deviceUrn,
            arm: true,
            radius: radius,
            centroid: centroid,
            operator: operator,
            propertyPath: propertyPath,
          },
        },
      )
      .then((x) => x.createGeoFenceAlarm)
  }

  deleteAlarm(id: string): Promise<MutationResponseGraphType> {
    return this.client
      .mutate<{ destroyAlarm: MutationResponseGraphType }, { alarmId: DestroyAlarmInput }>(
        this.api.url,
        destroyAlarmMutation,
        {
          alarmId: {
            id: id,
          },
        },
      )
      .then((x) => x.destroyAlarm)
  }

  replayEvents(aggregateId: string): Promise<MutationResponseGraphType> {
    return this.client
      .mutate<{ replayEvents: MutationResponseGraphType }, { aggregateId: IdentifierInput }>(
        this.api.url,
        replayEventsMutation,
        {
          aggregateId: {
            id: aggregateId,
          },
        },
      )
      .then((x) => x.replayEvents)
  }
}
