import { AuthenticatedTemplate, useMsal } from "@azure/msal-react"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import DashboardIcon from "@mui/icons-material/Dashboard"
import DeviceManagementIcon from "@mui/icons-material/Devices"
import SignoutIcon from "@mui/icons-material/ExitToApp"
import ImageIcon from "@mui/icons-material/Image"
import InfoIcon from "@mui/icons-material/Info"
import LiveHelpIcon from "@mui/icons-material/LiveHelp"
import MapIcon from "@mui/icons-material/Map"
import MenuIcon from "@mui/icons-material/Menu"
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant"
import UserManagementIcon from "@mui/icons-material/People"
import RefreshIcon from "@mui/icons-material/Refresh"
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser"
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Card,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Theme,
  Toolbar,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import classNames from "classnames"
import dayjs from "dayjs"
import React, { useState } from "react"
import { connect } from "react-redux"
import { NavigateFunction, useNavigate } from "react-router"
import { RoutePaths } from ".."
import { UserProfileView } from "../pages/UserManagementPage/components/UserProfile/UserProfileView"
import { AuthReducerState } from "../redux/reducers/authReducer"
import {
  DeviceFilterOptionsEnum,
  DeviceReducer,
  DeviceReducerState,
  deviceFilterOptions,
} from "../redux/reducers/deviceReducer"
import { AppDispatch, RootState } from "../redux/store/rootStore"
import { SOFTWARE_VERSION } from "../version"
import { PopOvercomponent } from "./PopOverComponent"
import { UserAvatarComponent } from "./UserAvatarComponent"
import { useUserInfoContext } from "./auth/UserInfoProvider"

const versionString = `Software Version ${SOFTWARE_VERSION.softwareVersion}`

interface OwnProps {
  id?: string
  showAlertFilterIcon?: boolean
  showRefreshIcon?: boolean
  refreshAction?: () => void
  refreshTimestamp?: number
  isLoading?: boolean
}

interface StateProps {
  displayName: string
  userAccount: string

  // TODO : not assigned ??
  // this is assigned by SearchNavBar.tsx as a children component.
  // children?: React.ReactElement
  alertCount?: number

  showAlertFilterIcon: boolean
  showRefreshIcon: boolean
  refreshData: () => void
}

interface DispatchProps {
  showAlertedDevices: () => void
}

type ComponentProps = StateProps & DispatchProps & OwnProps

const HeaderAppBar: React.FC<React.PropsWithChildren<ComponentProps>> = (props) => {
  const classes = useStyles()
  const { instance } = useMsal()
  const navigate: NavigateFunction = useNavigate()
  const [drawerState, setDrawerState] = useState(false)
  const { userInfoResource: userInfo } = useUserInfoContext()
  const onLogout = () => {
    instance.logout()
  }

  function makeDataAge(isLoading?: boolean, refreshTimestamp?: number) {
    if (isLoading === true) {
      return "Loading..."
    }

    if (!refreshTimestamp) {
      return "Not Loaded yet"
    }

    const now = dayjs(Date.now())
    const dataAge = dayjs(refreshTimestamp)

    const ageHours = now.diff(dataAge, "hours")
    const ageMinutes = now.diff(dataAge, "minutes")
    const ageSeconds = now.diff(dataAge, "seconds")

    const ageSecondsText = ageSeconds < 1 ? "Less than a second ago" : `${ageSeconds} Seconds ago`
    const ageMinutesText = ageMinutes < 1 ? ageSecondsText : `${ageMinutes} Minutes ago`
    const ageHoursText = ageHours < 1 ? ageMinutesText : `${ageHours} Hours ago`
    return ageHoursText
  }

  const dateAge = makeDataAge(props.isLoading, props.refreshTimestamp)

  const handleDrawerToggle = (event: React.KeyboardEvent | React.MouseEvent) => {
    // if (
    //   event &&
    //   event.type === "keydown" &&
    //   ["tab", "Shift"].some((x) => (event as React.KeyboardEvent).key === x)
    // ) {
    //   return
    // }

    setDrawerState(!drawerState)
  }

  // Attempt to read session expiry time from cookie expiry
  // checkCookie("x-ms-cpim-sso:p8dev1.onmicrosoft.com_0")

  // This aligns "log out" button to bottom of page:
  // style={{ height: "100%" }}
  const drawerContents = (
    <Box className={classes.drawContainer} style={{ height: "100%" }}>
      <div className={classes.drawerHeader}>
        <ListItemButton
          onClick={handleDrawerToggle}
          aria-label="Close Menu"
          title="Close Menu"
          sx={{ alignItems: "end" }}
        >
          <ListItemIcon>
            <ChevronLeftIcon />
          </ListItemIcon>
        </ListItemButton>
      </div>

      <div className={classes.drawContent}>
        <List component="nav" className={classes.list}>
          <AuthenticatedTemplate>
            <ListItem className={classes.userInfo}>
              <ListItemAvatar>
                <Avatar>
                  <ImageIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={userInfo?.profile.properties.displayName}
                secondary={userInfo?.profile.properties.userPrincipalName}
              />
            </ListItem>
            <Divider />

            <ListItemButton>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText secondary="Map" onClick={() => navigate("/")} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText secondary="Dashboards" onClick={() => navigate(RoutePaths.Dashboards)} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <DeviceManagementIcon />
              </ListItemIcon>
              <ListItemText
                secondary="Device Management"
                onClick={() => navigate(RoutePaths.DeviceManagment)}
              />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <UserManagementIcon />
              </ListItemIcon>
              <ListItemText secondary="User Management" onClick={() => navigate(RoutePaths.UserManagement)} />
            </ListItemButton>

            <ListItemButton className={classes.reload} onClick={() => props.refreshData()}>
              <ListItemIcon>
                <RefreshIcon
                  className={classNames(
                    classes.reloadIcon,
                    props.isLoading ? classes.reloadIconSpin : undefined,
                  )}
                />
              </ListItemIcon>
              <ListItemText primary="Refresh Device Data" secondary={"Refreshed: " + dateAge} />
            </ListItemButton>
            <Divider />
          </AuthenticatedTemplate>

          <ListItemButton>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <Link href="https://pollin8.co.nz/pages/contact-us" target="_blank" rel="noopener">
              {"Contact Us"}
            </Link>
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <LiveHelpIcon />
            </ListItemIcon>
            <Link href="https://pollin8.co.nz/pages/faq" target="_blank" rel="noopener">
              {"FAQ"}
            </Link>
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <VerifiedUserIcon />
            </ListItemIcon>
            <Link href="https://pollin8.co.nz/pages/legal" target="_blank" rel="noopener">
              {"Privacy Statement"}
            </Link>
          </ListItemButton>
        </List>
        <Divider />
        <List className={classes.bottomList}>
          <AuthenticatedTemplate>
            <ListItemButton onClick={() => onLogout()}>
              <ListItemIcon>
                <SignoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </AuthenticatedTemplate>

          <ListItem>
            <ListItemText className={classes.version} secondary={versionString} />
          </ListItem>
          {/* <ListItem>
            <ListItemText secondary="Diagnostics" onClick={() => navigate(RoutePaths.Diagnostics)} />
          </ListItem> */}
        </List>
      </div>
    </Box>
  )

  const renderCurrentUserProfile = () => {
    return (
      <Card id="profile" sx={{ boxShadow: "unset" }}>
        {userInfo ? (
          <UserProfileView user={userInfo.user.properties} roles={userInfo.roles.map((x) => x.properties)} />
        ) : null}
      </Card>
    )
  }

  return (
    <>
      <AppBar id={props.id} position="sticky">
        <Toolbar className={classes.toolbarcomponent}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Open Menu"
            title="Open Menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          {props.children}
          <AuthenticatedTemplate>
            <PopOvercomponent renderPopOver={renderCurrentUserProfile}>
              <Box sx={{ padding: 1, borderRadius: "50%", backgroundColor: "Background" }}>
                {userInfo ? <UserAvatarComponent displayName={userInfo.user.properties.displayName} /> : null}
              </Box>
            </PopOvercomponent>
            <React.Fragment>
              {props.showRefreshIcon ? (
                <IconButton
                  onClick={() => props.refreshData()}
                  aria-label="reload data"
                  title={"Refreshed: " + dateAge}
                >
                  <RefreshIcon
                    className={classNames(
                      classes.reloadIcon,
                      classes.reloadHeaderIcon,
                      props.isLoading ? classes.reloadIconSpin : undefined,
                    )}
                  />
                </IconButton>
              ) : null}
              {props.showAlertFilterIcon ? (
                <Badge
                  badgeContent={props.alertCount}
                  aria-label="Show Alerts"
                  title="Show Alerts"
                  color="error"
                  className={classes.notification}
                  onClick={props.showAlertedDevices}
                >
                  <NotificationImportantIcon />
                </Badge>
              ) : null}
            </React.Fragment>
          </AuthenticatedTemplate>
        </Toolbar>
      </AppBar>
      <div aria-label="left side menu">
        <SwipeableDrawer
          role="pressentation"
          variant="temporary"
          anchor="left"
          open={drawerState}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          hideBackdrop={true}
          ModalProps={{
            keepMounted: false,
          }}
        >
          {drawerContents}
        </SwipeableDrawer>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  toolbarcomponent: {
    display: "flex",
    flexDirection: "row",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawContainer: {
    display: "flex",
    flexDirection: "column",
  },
  drawerHeader: {
    display: "flex",
    // alignItems: "center",
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    // justifyContent: "flex-end",
    // backgroundColor: theme.palette.grey[100],
  },
  drawContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  list: {
    flex: 1,
  },
  bottomList: {},

  closeButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  logout: {
    alignItems: "center",
    margin: 2,
  },

  userInfo: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  notification: {
    marginLeft: "auto",
    cursor: "pointer",
  },
  drawerPaper: {
    width: 240,
  },
  version: {},
  reload: {},
  reloadIcon: {},

  "@keyframes reloadIconSpinAnimation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  reloadIconSpin: {
    animation: "$reloadIconSpinAnimation infinite 2s linear",
  },
  reloadHeaderIcon: {
    color: "white",
  },
}))

function mapStateToProps(state: RootState, ownProps: OwnProps): StateProps {
  const deviceState: DeviceReducerState = state.devices
  const authState: AuthReducerState = state.auth
  // const isHistoryLoading = state.deviceHistory.isLoading

  if (!authState.userProfile) {
    return {
      displayName: "--",
      userAccount: " ",
      // refreshTimestamp: deviceState.timestamp,
      // isLoading: deviceState.loading || isHistoryLoading,
      alertCount: deviceState.counts[DeviceFilterOptionsEnum.Alert] || 0,

      showAlertFilterIcon: false,
      showRefreshIcon: false,
      refreshData: () => {},
    }
  }

  return {
    displayName: authState.userProfile.displayName,
    userAccount: authState.userProfile.mail || authState.userProfile.userPrincipalName,
    // refreshTimestamp: deviceState.timestamp,
    // isLoading: deviceState.loading || isHistoryLoading,
    alertCount: deviceState.counts[DeviceFilterOptionsEnum.Alert] || 0,

    showAlertFilterIcon: ownProps.showAlertFilterIcon ?? false,
    showRefreshIcon: ownProps.showRefreshIcon ?? true,
    refreshData: () => {
      if (ownProps.refreshAction) ownProps.refreshAction()
    },
  }
}

function mapDispatchProps(dispatch: AppDispatch): DispatchProps {
  return {
    // refreshData: () => dispatch(DeviceReducer.refreshData()),
    showAlertedDevices: () => {
      const alertFilterOption = deviceFilterOptions.find((x) => x.id === DeviceFilterOptionsEnum.Alert)
      if (alertFilterOption) {
        dispatch(DeviceReducer.setFilterState({ filter: alertFilterOption.id }))
      }
    },
  }
}

export const HeaderAppBarComponent = connect(mapStateToProps, mapDispatchProps)(HeaderAppBar)
