import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ClientApi } from "../../api/tenantQL/ClientApi"
import { PageInfo } from "../../api/tenantQL/ManagementApi.autogenerated"
import { SingleDeviceResponse } from "../../api/tenantQL/TenantQL"
import { RootState } from "../store/rootStore"

export type Selectable = { selected: boolean }
export type Timestamped = { ts: number }
export type TimestampSingleDeviceResponse = SingleDeviceResponse & Selectable & Timestamped
export type DeviceHistory = {
  pageInfo?: PageInfo
  length: number
  schemaUrn?: string
  mostRecentTimestamp: number
  data: Record<string, TimestampSingleDeviceResponse>
}
export interface DeviceHistoryReducerState {
  currentDeviceUrn: string | undefined
  timestampOfLastLoad: number
  isLoading: boolean
  filterByTimeInSeconds: number
  enableFilterByLocation: boolean
  history: Record<string, DeviceHistory>
}

const initialState: DeviceHistoryReducerState = {
  currentDeviceUrn: undefined,
  history: {},
  isLoading: false,
  filterByTimeInSeconds: 24 * 60 * 60,
  enableFilterByLocation: true,
  timestampOfLastLoad: 0,
}

const additionalActions = {
  makeLoadDeviceHistoryAction: createAsyncThunk(
    "deviceHistory/load",
    async (arg: { api: ClientApi; deviceUrn: string; pageInfo?: PageInfo }, { dispatch, getState }) => {
      const state = getState() as RootState
      const { deviceHistory } = state
      const { addDeviceHistory, setIsLoading } = reducerSlice.actions

      if (!deviceHistory.isLoading)
        dispatch(setIsLoading({ deviceUrn: arg.deviceUrn, isLoading: true, pageInfo: arg.pageInfo }))

      const currentHistory = deviceHistory.history[arg.deviceUrn]
        ? deviceHistory.history[arg.deviceUrn]
        : makeEmptyHistory()

      console.log("History Length:", currentHistory.length)
      if (currentHistory.length > 1000) {
        console.warn("History Full", currentHistory.length)
        dispatch(setIsLoading({ deviceUrn: arg.deviceUrn, isLoading: false, pageInfo: arg.pageInfo }))
        return
      }

      if (currentHistory.pageInfo && currentHistory.pageInfo.hasNextPage === false) {
        console.warn("No More History", currentHistory.length)
        dispatch(setIsLoading({ deviceUrn: arg.deviceUrn, isLoading: false, pageInfo: arg.pageInfo }))
        return
      }

      const historyPageResponse = await arg.api.query.loadDeviceHistory({
        deviceUrn: arg.deviceUrn,
        continuationToken: arg.pageInfo?.endCursor || currentHistory.pageInfo?.endCursor,
      })

      dispatch(
        addDeviceHistory({
          deviceUrn: arg.deviceUrn,
          history: historyPageResponse.data,
        }),
      )

      const nextArg = {
        ...arg,
        pageInfo: historyPageResponse.pageInfo,
      }

      const hasMoreData = historyPageResponse.pageInfo.hasNextPage
      if (hasMoreData) {
        dispatch(additionalActions.makeLoadDeviceHistoryAction(nextArg))
      } else {
        console.warn("No More Data", currentHistory.length)
        dispatch(setIsLoading({ deviceUrn: arg.deviceUrn, isLoading: false, pageInfo: nextArg.pageInfo }))
      }
    },
  ),
  makeRefreshDeviceHistoryAction: createAsyncThunk(
    "deviceHistory/refresh",
    async (
      arg: { api: ClientApi; deviceUrn: string; startDate: number; endDate?: number; pageInfo?: PageInfo },
      { dispatch, getState },
    ) => {
      const state = getState() as RootState
      const { deviceHistory } = state
      const { addDeviceHistory, setIsLoading } = reducerSlice.actions

      const currentHistory = deviceHistory.history[arg.deviceUrn]
        ? deviceHistory.history[arg.deviceUrn]
        : makeEmptyHistory()

      // For now only handle refresh not load
      if (currentHistory.mostRecentTimestamp === 0) return

      if (!deviceHistory.isLoading)
        dispatch(setIsLoading({ deviceUrn: arg.deviceUrn, isLoading: true, pageInfo: arg.pageInfo }))

      const historyPagedResponse = await arg.api.query.loadDeviceHistory({
        deviceUrn: arg.deviceUrn,
        startDate: arg.startDate,
        endDate: arg.endDate,
        continuationToken: arg.pageInfo?.endCursor,
      })

      dispatch(
        addDeviceHistory({
          deviceUrn: arg.deviceUrn,
          history: historyPagedResponse.data,
        }),
      )

      const nextArg = {
        ...arg,
        pageInfo: historyPagedResponse.pageInfo,
      }

      const hasMoreData = historyPagedResponse.pageInfo.hasNextPage
      if (hasMoreData) {
        dispatch(additionalActions.makeRefreshDeviceHistoryAction(nextArg))
      } else {
        console.warn("No More Data", currentHistory.length)
        dispatch(setIsLoading({ deviceUrn: arg.deviceUrn, isLoading: false, pageInfo: nextArg.pageInfo }))
      }
    },
  ),
}

const reducerSlice = createSlice({
  name: "deviceHistory",
  initialState,
  reducers: {
    setIsLoading(
      state,
      action: PayloadAction<{ deviceUrn: string; isLoading: boolean; pageInfo?: PageInfo }>,
    ) {
      const { isLoading, deviceUrn, pageInfo } = action.payload
      if (!state.history[deviceUrn]) state.history[deviceUrn] = makeEmptyHistory()

      state.isLoading = isLoading
      state.currentDeviceUrn = deviceUrn
      if (pageInfo) state.history[deviceUrn].pageInfo = pageInfo
    },
    addDeviceHistory(
      state,
      action: PayloadAction<{ deviceUrn: string; history: Array<SingleDeviceResponse> }>,
    ) {
      const { deviceUrn, history } = action.payload
      state.timestampOfLastLoad = Date.now()
      state.currentDeviceUrn = deviceUrn

      if (!state.history[deviceUrn]) state.history[deviceUrn] = makeEmptyHistory()
      const currentHistory = state.history[deviceUrn]

      history.forEach((x) => {
        const ts = Date.parse(x.timestamp)
        if (!currentHistory.schemaUrn) currentHistory.schemaUrn = x.schemaUrn
        if (ts > currentHistory.mostRecentTimestamp) currentHistory.mostRecentTimestamp = ts
        currentHistory.data[x.id] = { ...x, ts, selected: true }
      })
      currentHistory.length = Object.keys(currentHistory.data).length
    },
    setTimeFilterSeconds(state, action: PayloadAction<{ seconds: number }>) {
      const { seconds } = action.payload
      state.filterByTimeInSeconds = seconds
    },
    setLocationFiltering(state, action: PayloadAction<{ enableLocationFilter: boolean }>) {
      const { enableLocationFilter } = action.payload
      state.enableFilterByLocation = enableLocationFilter
    },
    setSelectionModel(state, action: PayloadAction<{ fullSet: Array<string>; selected: Array<string> }>) {
      const { currentDeviceUrn, history } = state
      if (!currentDeviceUrn) return
      const deviceHistory = history[currentDeviceUrn]?.data
      if (!deviceHistory) return

      // Deselect full set first
      action.payload.fullSet.forEach((x) => {
        const record = deviceHistory[x]
        if (record) record.selected = false
      })

      // Selected selected by id
      action.payload.selected.forEach((x) => {
        if (deviceHistory[x]) deviceHistory[x].selected = true
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(additionalActions.makeLoadDeviceHistoryAction.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(additionalActions.makeLoadDeviceHistoryAction.fulfilled, (state) => {
        state.isLoading = false
        if (state.currentDeviceUrn) {
          const history = state.history[state.currentDeviceUrn]
          history.pageInfo = undefined
        }
      })

    builder.addCase(additionalActions.makeRefreshDeviceHistoryAction.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const deviceHistoryReducer = {
  reducer: reducerSlice.reducer,
  setTimeRangeSeconds: reducerSlice.actions.setTimeFilterSeconds,
  setLocationFiltering: reducerSlice.actions.setLocationFiltering,
  setSelectionModel: reducerSlice.actions.setSelectionModel,
  ...additionalActions,
}
function makeEmptyHistory(): DeviceHistory {
  return { data: {}, length: 0, mostRecentTimestamp: 0 }
}
